import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { QuestionOptionService } from '../../../services/question-option.service';

export interface Option {
  id: number;
}

@Component({
  selector: 'app-add-option',
  templateUrl: './add-option.component.html',
  styleUrls: ['./add-option.component.scss']
})
export class AddOptionComponent implements OnInit {

  optionForm: FormGroup;
  id: number;
  newOptionData: any = [];

  constructor(
    public dialogRef: MatDialogRef<AddOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Option,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private questionOption: QuestionOptionService
  ) { 
  }

  ngOnInit(): void {

    this.optionForm = this.fb.group({
      text: ['']
    });

  }

  onSubmit() {

    this.newOptionData = [];

    this.newOptionData.push([
      {
        text: this.optionForm.value.text
      }
    ]);

    console.log('newOptionData', this.newOptionData[0]);

    this.questionOption.addQuestionOption(this.data.id, this.newOptionData[0]).subscribe((res) => {
      console.log('Created option data', res);
      this.openSnackbarSuccessMessage('Option added successfully');
      this.ngOnInit();
      // this.dialogRef.close();
      // this.router.navigate(['/questions/questions/edit-question', this.data.id]);
    },
    (err: any) => {
      console.log(err);
      if (err.error === "Option Key already exists") {
        this.openSnackbarSuccessMessage('Option Key already exists');
      }
    }
    );
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
