import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-basemodifiedquestions',
  templateUrl: './basemodifiedquestions.component.html',
  styleUrls: ['./basemodifiedquestions.component.scss']
})
export class BaseModifiedQuestionsComponent implements OnInit {

  questionLogData: any;
  show_modified_questions_from_base: boolean = true;
  newQuestionData: any = [];
  updateQuestionData: any = {};
  updateQuestionOption: any = [];
  updateQuestionCountry: any = [];
  updateQuestionBlackListed: any = [];
  updateQuestionWhiteListed: any = [];
  updateQuestionTags: any = [];
  updateQuestionOptionCountry: any = [];
  updateQuestionTranslation: any = [];
  updateQuestionOptionTrans: any = [];
  updateQuestionDocumentType: any = [];
  updateQuestionOptionParent: any = [];
  updateClientQuestionWithBase: any= [];
  

  constructor(
    private questionService: QuestionService,
    private dialogRef: MatDialogRef<BaseModifiedQuestionsComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {

    this.questionLogData = [];
    this.questionService.getModifiedQuestionsByBase(this.data, this.show_modified_questions_from_base).subscribe((res) => {
          
      res.data.forEach((log) => {
        this.questionLogData.push({
          action: log.action,
          action_By: log.action_By,
          action_Date: log.action_Date,
          action_Date_To_Display: log.action_Date_To_Display,
          attribute: log.action == "INSERT" || log.action == "DELETE" ? log.entity : (log.attribute != null ? log.attribute : log.entity),
          entity: log.entity,
          previous_Value: log.previous_Value,
          subEntity: log.subEntity,
          updated_Value: log.updated_Value ,
          updatedValue: log.action == "INSERT" || log.action == "DELETE" ? log.subEntity : log.updated_Value.updatedValue         
        });
      });

      this.questionLogData.forEach((logData) => {
        if(logData.attribute.toLowerCase() == "question_tags")
        {
          logData.attribute = "Tags";
        }
        else if(logData.attribute.toLowerCase() == "default")
        {
          logData.attribute = "Default value";
        }
        else if(logData.attribute.toLowerCase() == "multi_choice")
        {
          logData.attribute = "Multi choice";          
        }
        else if(logData.attribute.toLowerCase() == "profile_type")
        {
          logData.attribute = "Is profile - Answer obtained";          
        }
        else if(logData.attribute.toLowerCase() == "help")
        {
          logData.attribute = "Help tooltip";          
        }
        else if(logData.attribute.toLowerCase() == "required")
        {
          logData.attribute = "Is required";          
        }
        else if(logData.attribute.toLowerCase() == "static")
        {
          logData.attribute = "Is static";          
        }
        else if(logData.attribute.toLowerCase() == "text")
        {
          logData.attribute = "Question key";          
        }
        else if(logData.attribute.toLowerCase() == "max_iterations")
        {
          logData.attribute = "Max iterations";          
        }
        else if(logData.attribute.toLowerCase() == "validation")
        {
          logData.attribute = "Validation rule";          
        }
        else if(logData.attribute.toLowerCase() == "documenttypetext")
        {
          logData.attribute = "Document type";
        }
        else if(logData.attribute.toLowerCase() == "validation_help")
        {
          logData.attribute = "Validation help";
        }
        else if(logData.attribute.toLowerCase() == "variant")
        {
          logData.attribute = "Datetime variant";
        }
        else if(logData.attribute.toLowerCase() == "company_type")
        {
          logData.attribute = "Company type";
        }        
      });
    });
    console.log('Activity log', this.questionLogData);
  }

  updateClient()
  {
    this.questionService
    .updateClientQuestion(this.data, this.newQuestionData)
    .subscribe(
      (res: any) => {
        this.openSnackbarSuccessMessage('Question updated successfully');
       // this.router.navigate(['/questions/questions/edit-question', this.questionID]);
    });
  }

  checkBoxChecked(checkbox: MatCheckbox, log: any ) { 
    this.newQuestionData.push({
      action: log.action,
      action_By: log.action_By,
      action_Date: log.action_Date,
      action_Date_To_Display: log.action_Date_To_Display,
      attribute: log.attribute,
      entity: log.entity,
      previous_Value: log.previous_Value,
      subEntity: log.subEntity,
      updated_Value: log.updated_Value ,
      updatedValue: log.updated_Value.updatedValue         
    });  
  }

  onUpdateQuestionSubmit()
  {
    console.log("Modified question");
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
