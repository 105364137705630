import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QuestionService } from '../../services/question.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ParentQuestionComponent } from '../parent-question/parent-question.component';
import { AddOptionComponent } from './add-option/add-option.component';
import { QuestionOptionService } from '../../services/question-option.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddQuestionCountriesComponent } from './add-question-countries/add-question-countries.component';
import { QuestionCountryService } from '../../services/question-country.service';
import { RemoveQuestionCountriesComponent } from './remove-question-countries/remove-question-countries.component';
import { PwdHostComponent } from './pwd/pwd-host/pwd-host.component';
import { A1HomeComponent } from './a1/a1-home/a1-home.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DocumentsService } from 'src/app/modules/documents/services/documents.service';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { TranslateQuestionOptionComponent } from '../translate-question-option/translate-question-option.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss'],
})
export class EditQuestionComponent implements OnInit {

  editQuestionForm: FormGroup;

  filteredGroup: Observable<string[]>;
  filteredAnswers: Observable<string[]>;
  filteredVisibility: Observable<any[]>;
  filteredParentType: Observable<any[]>;
  filteredCompanies: Observable<any[]>;
  filteredDatetime: Observable<any[]>;
  filteredDocType: Observable<any[]>;

  filteredFruits: Observable<string[]>;
  options: any = [];
  visibilityDropdown: string[] = ['Enabled', 'Disabled'];
  answerObtained = {
    'Automatically from external API': 'automatic',
    'Manually in user profile': 'manual',
    'Manually in user profile or trip creation process': 'manual-trip'
  };
  showCompaines = {
    'internal': 'internal',
    'external': 'external',
    'user': 'user'
  };
  parentRuleType = {
    'Regex pattern': 'pattern',
    'At least one from a pipe separated list': 'contains'
  };
  datetimeVariant = {
    'Date': 'date',
    'Time': 'time',
    'Datetime': 'datetime'
  };

  displayedColumns1: string[] = ['position', 'notifications'];
  questionOptionColumn: string[] = ['id', 'position', 'actionsNew', 'answerkey', 'parents', 'actions'];
  questionCountryColumn: string[] = ['id', 'name', 'code'];

  matcher = new MyErrorStateMatcher();
  newQuestionData: any[] = [];
  clearParentData: any = [];

  id: number;
  question = '';
  type = '';
  visibility = '';
  max_Iterations: number;

  // rules
  required: boolean;
  is_Profile: boolean;
  profile_Type: '';
  is_Document: boolean;
  is_Purpose: null;
  document_Type_Id: '';
  is_Prefilled: boolean;
  is_Static: boolean;
  default: '';
  dateTime: null;
  variant: '';
  multi_Choice: boolean;
  min_Length: number;
  max_Length: number;
  min_Value: number;
  max_Value: number;
  validation: string; // TODO: validation pattern (catch the error message show it the mat-error)
  validation_help: '';
  help: '';
  placeholder: '';
  order: '';
  // show for companies
  company: '';
  // company_Type: '';
  // tags: string[];

  // parent
  parent: '';
  // parent_Type: ''; // set to null if parent == null else parent
  parent_Rule: ''; // set to null if parent == null else parent


  questionID: any;
  questionGroupData: any = [];
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  // tags: string[] = ['Apple'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  listTagsData: any;
  questionDataList: any = [];
  newQuestionDataList: any;
  questionOptionData: any = [];
  questionOptionDataLength: any = [];
  newQuestionOptionData: any = [];
  questionCountryBlacklistData: any = [];
  questionCountryWhitelistData: any = [];
  blacklistDataLength: number;
  whitelistDataLength: number;
  parentID: number;
  parentText: string;
  parentType: string;
  noParentQuestion: any;
  editQuestionData: any = [];
  a1AllCountriesAdded: boolean;
  isA1ToggleChecked: boolean;
  isPWDToggleChecked: boolean;
  documentList: any = [];
  newDocumentList: any = [];
  validationInput = null;

  // tags
  listOfTags: any = [];
  tagsData: any = [];
  tagsByID: any = [];
  newtagsByID: any = [];
  questionTagList: any = [];
  // questionDataById: any = [];
  newTagListArray: any;

  // company
  companiesData: string[] = [];

  // registrations
  registrationData: any = [];
  a1RegistrationData: any = [];
  pwdregistrationData: any = [];
  alHomeCountriesData: MatTableDataSource<any> = null;
  pwdHostCountriesData: MatTableDataSource<any> = null;
  a1RegistrationID: any = [];
  pwdResigrationID: any = [];

  alHomeCountriesDataLength: any;
  pwdHostCountriesDataLength: any;

  isTextType: boolean;
  isBoolType: boolean;
  isNumberType: boolean;
  isChoiceType: boolean;
  isDatetimeType: boolean;
  isCountryType: boolean;
  isCompanyType: boolean;

  hideParent: boolean;
  isReadOnly: boolean;
  newClientId: any;
  newOrderID: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;


  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private questionService: QuestionService,
    private questionGroupService: QuestionGroupsService,
    private questionOptionService: QuestionOptionService,
    private questionCountryService: QuestionCountryService,
    private documentsService: DocumentsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef
  ) {

    this.editQuestionForm = this.fb.group({

      // question
      group: ['', Validators.required],
      question: ['', [Validators.required, Validators.pattern('^[a-z0-9_]*$')]],
      visibility: ['', Validators.required],
      max_Iterations: ['', [Validators.min(1), Validators.max(1000), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],

      // rules
      required: [''],
      order: null,
      is_Profile: [''],
      profile_Type: [''],
      is_Document: [''],
      document_Type_Id: [''],
      is_Prefilled: [''],
      is_Static: [''],
      is_Encrypted: [''],
      multi_Choice: [''],
      default: [''],
      variant: [''],
      min_Length: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      max_Length: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      min_Value: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      max_Value: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      validation: [''],
      validation_help: [''],
      help: [''],
      placeholder: ['', Validators.maxLength(500)],
      // show for companies
      company: [''],
      company_Type: [''],
      tags: [''],

      // parent
      parent: [''],
      parent_Type: [''], // set to null if parent == null else parent
      parent_Rule: [''] // set to null if parent == null else parent
    });

  }

  ngOnInit() {

    this.listTagsData = [];
    this.newDocumentList = [];
    this.questionTagList = [];
    this.newQuestionDataList = [];
    this.questionDataList = [];
    this.newTagListArray = [];
    this.noParentQuestion;

    this.isTextType = false;
    this.isBoolType = false;
    this.isNumberType = false;
    this.isChoiceType = false;
    this.isDatetimeType = false;
    this.isCountryType = false;
    this.isCompanyType = false;

    this.hideParent = false;
    this.isReadOnly = false;

    // disable for clientid
    if (localStorage.getItem('user_details')) {
      this.newClientId = JSON.parse(localStorage.getItem('user_details'));
      if (this.newClientId.organizationId !== 0) {
        this.isReadOnly = true;
      }
    }

    this.questionID = +this.route.snapshot.params['id'];

    this.getQuestionID();

    // Registrations api
    this.getRegistrations();

    this.companiesData = Object.values(this.showCompaines);

    // Question group api
    this.questionGroupService.getQuestionGroups().subscribe((res) => {
      // console.log('Question groups data', res.data);
      this.questionGroupData = res.data;
      this.options.push(this.questionGroupData);

      this.filteredGroup = this.editQuestionForm.get('group').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.options[0].filter((option: any) => option.name.toLowerCase().includes(term.toString().toLowerCase()));
        })
      );
    });

    // Get document types
    this.documentsService.getDocumentstypes().subscribe((res) => {
      this.documentList = res.data;

      res.data.forEach((e) => {
        if (e.isEnabled === true) {
          this.newDocumentList.push({
            id: e.id,
            documentTypeName: e.documentTypeName,
            documentTypeText: e.documentTypeText,
            isEnabled: e.isEnabled,
            services: e.services,
            tripsAddition: e.tripsAddition
          });
        }
      });

      this.filteredDocType = this.editQuestionForm.get('document_Type_Id').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.newDocumentList.filter((docType: any) => docType.documentTypeName.toLowerCase().includes(term.toString().toLowerCase()));
        })
      );
    });



    // filter part

    this.filteredVisibility = this.editQuestionForm.get('visibility').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return this.visibilityDropdown.filter((visibile: any) => visibile.toLowerCase().includes(term.toString().toLowerCase()));
      })
    );
    

    this.filteredAnswers = this.editQuestionForm.get('profile_Type').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return Object.keys(this.answerObtained).filter((type: any) => type.toLowerCase().includes(term?.toString()?.toLowerCase()));
      })
    );

    this.filteredParentType = this.editQuestionForm.get('parent_Type').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return Object.keys(this.parentRuleType).filter((type: any) => type.toLowerCase().includes(term ? term.toLowerCase() : ''));
      })
    );

    this.filteredCompanies = this.editQuestionForm.get('company_Type').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return Object.keys(this.showCompaines).filter((type: any) => type.toLowerCase().includes(term.toString().toLowerCase()));
      })
    );

    this.filteredDatetime = this.editQuestionForm.get('variant').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return Object.keys(this.datetimeVariant).filter((type: any) => type.toLowerCase().includes(term.toString().toLowerCase()));
      })
    );

  }

  initName(name: string): FormControl {
    return this.fb.control(name);
  }

  getQuestionID() {

    this.questionDataList = [];

    this.questionService.getQuestionById(this.questionID).subscribe((res: any) => {
      this.questionDataList = Object.assign({}, res.data);
      console.log('Question object assinged data', this.questionDataList);

      console.log('Behaviour subject', this.questionService.getNewQuestionData(this.questionDataList));

      this.questionService.getNewQuestionData(this.questionDataList);

      this.getQuestionTagData();

      this.noParentQuestion = this.questionDataList.parent;
      this.question = this.questionDataList.question;
      console.log('noParentQuestion', this.noParentQuestion);

      if (this.questionDataList.parent !== null) {
        this.parentID = this.questionDataList.parent.id;
        this.parentText = this.questionDataList.parent.question;
      }

      if (this.questionDataList.country !== null) {
        this.getQuestionCountryBlacklist(this.questionID);
        this.getQuestionCountryWhitelist(this.questionID);
      }

      if (res.data.type === "text") {
        this.isTextType = true;
      } else if (res.data.type === "bool") {
        this.isBoolType = true;
      } else if (res.data.type === "number") {
        this.isNumberType = true;
      } else if (res.data.type === "choice") {
        this.isChoiceType = true;
        this.getQuestionOptionData();
      } else if (res.data.type === "datetime") {
        this.isDatetimeType = true;
      } else if (res.data.type === "country") {
        this.isCountryType = true;
      } else if (res.data.type === "company") {
        this.isCompanyType = true;
      }

      if (res.data.tags.length !== 0) {
        this.listTagsData.push(res.data.tags[0].name);
      }

      this.editQuestionForm.setValue({
        group: res.data.group,
        question: res.data.question,
        visibility: res.data.visibility,
        max_Iterations: res.data.max_Iterations === null ? null : res.data.max_Iterations,

        required: res.data.required,
        is_Profile: res.data.is_Profile,
        order: null,
        profile_Type: res.data.profile_Type === null ? null : this.profileTypeKeyConvert(),
        is_Document: res.data.is_Document,
        document_Type_Id: res.data.document_Type,
        is_Prefilled: res.data.is_Prefilled,
        is_Encrypted: res.data.is_Encrypted,
        is_Static: res.data.is_Static,
        default: res.data.default === null ? null : res.data.default,
        variant: (res.data.dateTime === null || res.data.dateTime === undefined || res.data.dateTime.variant === undefined || res.data.dateTime.variant === null) ? null : this.datetimeTypeKeyConvert(),
        multi_Choice: (res.data.choice === null || res.data.choice === undefined || res.data.choice.multi_Choice === undefined) ? null : res.data.choice.multi_Choice,
        min_Length: (res.data.text === null || res.data.text === undefined || res.data.text.min_Length === undefined) ? null : res.data.text.min_Length,
        max_Length: (res.data.text === null || res.data.text === undefined || res.data.text.max_Length === undefined) ? null : res.data.text.max_Length,
        min_Value: (res.data.number === null || res.data.number === undefined || res.data.number.min_Value === undefined) ? null : res.data.number.min_Value,
        max_Value: (res.data.number === null || res.data.number === undefined || res.data.number.max_Value === undefined) ? null : res.data.number.max_Value,
        validation: res.data.validation === null ? null : res.data.validation,
        validation_help: res.data.validation_help === null ? null : res.data.validation_help,
        help: res.data.help,
        placeholder: res.data.placeholder,
        company: res.data.company,
        company_Type: res.data.company_Type,
        tags: res.data.tags.length === 0 ? null : res.data.tags[0],

        parent: res.data.parent === null ? null : res.data.parent,
        parent_Type: res.data.parent === null ? null : res.data.parent_Type,
        parent_Rule: res.data.parent === null ? null : res.data.parent_Rule
      });
      console.log('editQuestionForm', this.editQuestionForm.value);

    });
  }

  getQuestionParent() {

    this.questionDataList = [];

    this.questionService.getQuestionById(this.questionID).subscribe((res: any) => {
    this.questionDataList = Object.assign({}, res.data);
    this.noParentQuestion = this.questionDataList.parent;
    if (this.questionDataList.parent !== null) {
        this.parentID = this.questionDataList.parent.id;
        this.parentText = this.questionDataList.parent.question;
    }
    //this.editQuestionForm.setValue({
    //parent: res.data.parent === null ? null : res.data.parent,
    //parent_Type: res.data.parent === null ? null : res.data.parent.type,
    //parent_Rule: res.data.parent === null ? null : res.data.parent_Rule
    //});
  });
}

  getQuestionTagData() {

    this.tagsData = [];
    this.questionService.getQuestionTag().subscribe((res) => {
      console.log('Question tag data', res);
      this.questionTagList = res.data;

      this.questionService.questionData.subscribe((res) => {
        this.newQuestionDataList = res;
      });

      // console.log('newquestionDataList', this.newQuestionDataList);

      if (this.newQuestionDataList && this.newQuestionDataList !== undefined) {
        this.questionTagList.forEach((e) => {
          if (this.newQuestionDataList.type === e.type) {
            this.tagsData.push({
              id: e.id,
              name: e.name,
              type: e.type
            });
          }
        });
      }

      this.tagsData = this.tagsData.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.name === value.name
        ))
      );
      // console.log('filtered tag list data', this.tagsData);
    });
  }

  getQuestionOptionData() {

    this.newQuestionOptionData = [];

    this.questionOptionService.getOptionByQuestionId(this.questionID).subscribe((res) => {
      console.log('Get question option data', res.data);

      res.data.forEach((e) => {
        this.newQuestionOptionData.push({
          allowNoneCompany: e.allowNoneCompany,
          id: e.id,
          order: e.order,
          parents: (e.parents.length === 0) ? 'All options' : e.parents[0].id,
          text: e.text,
          translations: e.translations
        });
      });

      this.newQuestionOptionData.forEach((el) => {
        el['isEditable'] = false;
      });
      console.log('Get newQuestionOptionData row data', this.newQuestionOptionData);

      this.questionOptionData = new MatTableDataSource<any>(this.newQuestionOptionData);
      this.questionOptionData.paginator = this.paginator;
      this.questionOptionData.sort = this.sort;

      this.questionOptionDataLength = this.questionOptionData.filteredData.length;

    });

  }

  getQuestionCountryBlacklist(id: number) {

    this.questionCountryService.getQuestionCountryBlacklist(id).subscribe((res) => {
      // console.log('questionCountryBlacklistData', res.data);

      this.questionCountryService.getFilteredQuestionData(res.data);

      this.questionCountryBlacklistData = new MatTableDataSource<any>(res.data);
      this.questionCountryBlacklistData.paginator = this.paginator;
      this.questionCountryBlacklistData.sort = this.sort;

      this.blacklistDataLength = this.questionCountryBlacklistData.filteredData.length;
    });
  }

  getQuestionCountryWhitelist(id: number) {

    this.questionCountryService.getQuestionCountryWhitelist(id).subscribe((res) => {
      // console.log('questionCountryWhitelistData', res.data);

      this.questionCountryWhitelistData = new MatTableDataSource<any>(res.data);
      this.questionCountryWhitelistData.paginator = this.paginator;
      this.questionCountryWhitelistData.sort = this.sort;

      this.whitelistDataLength = this.questionCountryWhitelistData.filteredData.length;
    });
  }

  getRegistrations() {
    this.questionService.getRegistrations().subscribe((res) => {
      this.registrationData = res.data;
      this.a1RegistrationData = res.data[0];
      this.pwdregistrationData = res.data[1];
     

      // Get A1 home countries
      this.questionCountryService.getA1HomeCountries(this.questionID, this.a1RegistrationData.id).subscribe((res) => {
        // console.log('alHomeCountriesData', res.data);
        this.alHomeCountriesData = new MatTableDataSource<any>(res.data);
        this.alHomeCountriesDataLength = this.alHomeCountriesData.filteredData.length;

      });

      // Get PWD host countries
      this.questionCountryService.getPwdHostCountries(this.questionID, this.pwdregistrationData.id).subscribe((res) => {
        // console.log('pwdHostCountriesData', res.data);
        this.pwdHostCountriesData = new MatTableDataSource<any>(res.data);
        this.pwdHostCountriesDataLength = this.pwdHostCountriesData.filteredData.length;
      });

      this.questionCountryService.getAllA1Countries(this.questionID, this.a1RegistrationData.id).subscribe((res) => {
         console.log('alHomeCountriesData', res.data);
         this.isA1ToggleChecked = res.data.enableAllHomeCountries;
      });

      this.questionCountryService.getAllPwdCountries(this.questionID, this.pwdregistrationData.id).subscribe((res) => {
        console.log('alHomeCountriesData', res.data);        
        this.isPWDToggleChecked = res.data.enableAllHostCountries; 
     });
    });

  }

  // tags

  get tags(): FormControl {
    return this.editQuestionForm.get('tags') as FormControl;
  }

  removeRegistraion(tag: string) {
    const tagValue = this.tags.value as string[];
    this.removeFirst(tagValue, tag);
    this.tags.setValue(tagValue); // To trigger change detection
  }

  get company_Type(): FormControl {
    return this.editQuestionForm.get('company_Type') as FormControl;
  }

  removeCompanyType(tag: string) {
    const companyValue = this.company_Type.value as string[];
    this.removeFirst(companyValue, tag);
    this.company_Type.setValue(companyValue); // To trigger change detection
  }

  removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  groupDisplay(group) {
    return group ? group.name : '';
  }

  doctypeNameDisplay(docType) {
    return docType ? docType.documentTypeName : '';
  }

  tagsDisplay(tags) {
    return tags ? tags.name : '';
  }

  // convert keys into values
  profileTypeValueConvert() {
    let newtype = Object.entries(this.answerObtained).filter((key) => key.includes(this.questionData.profile_Type.value));
    console.log('new profile type', newtype);
    return newtype[0][1];
  }

  datetimeTypeValueConvert() {
    let newtype = Object.entries(this.datetimeVariant).filter((key) => key.includes(this.questionData.variant.value));
    console.log('datatime value', newtype);
    return newtype[0][1];
  }

  companyTypeValueConvert() {
    let newtype = Object.entries(this.showCompaines).filter((key) => key.includes(this.questionData.company_Type.value));
    // console.log('datatime value', newtype);
    return newtype[0][1];
  }

  // convert values into keys
  profileTypeKeyConvert() {
    let newtype = Object.entries(this.answerObtained).filter((value) => value.includes(this.questionDataList.profile_Type));
    return newtype[0][0];
  }

  datetimeTypeKeyConvert() {
    let newtype = Object.entries(this.datetimeVariant).filter((value) => value.includes(this.questionDataList.dateTime.variant));
    console.log('new date time variant', newtype);
    return newtype[0][0];
  }

  companyTypeKeyConvert() {
    let newtype = Object.entries(this.showCompaines).filter((value) => value.includes(this.questionDataList.company_Type));
    console.log('company type key converted', newtype);
    return newtype[0][0];
  }

  translateQuestion(optionId) {
    const dialogRef = this.dialog.open(TranslateQuestionOptionComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: [optionId, this.questionID]
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  removeDependency() {
    this.clearParentData.push({
      text: this.questionDataList.question,
      type: this.questionDataList.type,
      group: this.questionDataList.group.id,
      required: this.questionDataList.required,
      visibility: this.questionDataList.visibility,
      parent: null,
      parent_Rule: null,
      parent_Type: null
    });

    console.log('new question clear data', this.clearParentData[0]);
    this.questionService.updateParentQuestion(this.questionID, this.clearParentData[0]).subscribe((res) => {
      //this.getQuestionID();
     this.noParentQuestion = res.data.parent;
      this.openSnackbarSuccessMessage('Parent has been removed successfully');
    });

  }

  get questionData() {
    return this.editQuestionForm.controls;
  }

  onEditQuestionSubmit() {

    // console.log('After edited question data', this.questionData);

    this.newtagsByID = [];
    if(this.questionData.tags.value !== '' && this.questionData.tags.value !== null){
      this.newtagsByID.push(this.questionData.tags.value.id);
    }
    this.newQuestionData = [];

    this.newQuestionData.push({
      group: this.questionData.group.value.id,
      text: this.questionData.question.value,
      type: this.questionDataList.type,
      visibility: this.questionData.visibility.value.toLowerCase(),
      max_Iterations: this.questionData.max_Iterations.value === '' ? null : this.questionData.max_Iterations.value,
      order: this.questionData.order.value,
      required: this.questionData.required.value,
      is_Profile: this.questionData.is_Profile.value,
      profile_Type: this.questionData.profile_Type.value === null ? null : this.profileTypeValueConvert(),
      is_Document: this.questionData.is_Document === undefined ? null : this.questionData.is_Document.value,
      document_Type_Id: this.questionData.document_Type_Id.value === null ? null : this.questionData.document_Type_Id.value.id,
      is_Prefilled: this.questionData.is_Prefilled.value === null ? null : this.questionData.is_Prefilled.value,
      is_Encrypted: this.questionData.is_Encrypted.value === null ? null : this.questionData.is_Encrypted.value,
      is_Static: this.questionData.is_Static.value,
      default: this.questionData.default.value === null ? null : this.questionData.default.value,
      min_Length: this.questionData.min_Length.value === null ? null : this.questionData.min_Length.value,
      max_Length: this.questionData.max_Length.value === null ? null : this.questionData.max_Length.value,
      min_Value: this.questionData.min_Value.value === null ? null : this.questionData.min_Value.value,
      max_Value: this.questionData.max_Value.value === null ? null : this.questionData.max_Value.value,
      multi_Choice: this.questionData.multi_Choice.value === undefined ? null : this.questionData.multi_Choice.value,
      variant: this.questionData.variant.value === null ? null : this.datetimeTypeValueConvert(),
      validation: this.questionData.validation.value === null ? null : this.questionData.validation.value,
      validation_help: this.questionData.validation_help.value === null ? null : this.questionData.validation_help.value,
      help: this.questionData.help.value === null ? null : this.questionData.help.value,
      placeholder: this.questionData.placeholder.value === null ? null : this.questionData.placeholder.value,
      is_Purpose: this.questionDataList.is_Purpose === null ? null : this.questionDataList.is_Purpose,
      company_Type: this.questionData.company_Type.value === null ? null : this.questionData.company_Type.value,
      tags: this.questionData.tags.value === null ? null : this.newtagsByID,
      parent: this.noParentQuestion === null ? null : this.noParentQuestion.id,
      parent_Type: this.questionData.parent !== null ? this.questionData.parent_Type.value : null,
      parent_Rule: this.questionData.parent !== null ? this.questionData.parent_Rule.value : null,
    });

    console.log('New question data', this.newQuestionData[0]);

    this.questionService
      .updateQuestion(this.questionID, this.newQuestionData[0])
      .subscribe(
        (res: any) => {
          this.openSnackbarSuccessMessage('Question has been saved successfully');
          this.router.navigate(['/questions/questions/edit-question', this.questionID]);
        },
        (err: any) => {
          console.log(err);
          if (err.error === "Purpose question already exists") {
            this.openSnackbarSuccessMessage('Purpose question already exists');
            this.router.navigate(['/questions/questions/edit-question', this.questionID]);
          }
        }
      );
  }

  exitGroup() {
    this.router.navigate(['/questions/questions']); // TODO: Check which path needs to redirect
  }

  removeQuestionOption(id: number) {
    this.questionOptionService.removeQuestionOption(id).subscribe((res) => {
      console.log(`${id} is removed from the option table`, res);
      this.openSnackbarSuccessMessage('Question option removed successfully');
      this.getQuestionOptionData();
    });
  }

  addQuestionCountry() {
    const dialogRef = this.dialog.open(AddQuestionCountriesComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: this.questionDataList
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      this.getQuestionCountryBlacklist(this.questionID);
    });
  }

  removeQuestionCountry() {
    const dialogRef = this.dialog.open(RemoveQuestionCountriesComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: this.questionDataList
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getQuestionCountryWhitelist(this.questionID);
    });
  }

  addOptionDialog(): void {
    const dialogRef = this.dialog.open(AddOptionComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: this.questionDataList
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('After add option dialog closed', result);
      this.getQuestionOptionData();
    });
  }

  selectQuestion(): void {
    const dialogRef = this.dialog.open(ParentQuestionComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: this.questionDataList
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Parent data', result);
      this.getQuestionParent();
    });
  }

  /**
   * A1 Home Service
   */

  addA1HomeCountries(): void {
    const dialogRef = this.dialog.open(A1HomeComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: [this.questionDataList, this.registrationData]
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('after closed data', result);
      this.getRegistrations();
    });
  }

  addAlAllCountries(id: number, event: MatSlideToggleChange) {

    if(event.checked ? confirm("Enable all countries checked") : confirm("Enable all countries unchecked")) {
      if (event.checked) {
        this.isA1ToggleChecked = true;
      } else {
        this.isA1ToggleChecked = false;
      }
      this.a1RegistrationID.push({
        "registrationID": id
      });
      this.questionCountryService.updateAllA1Countries(this.questionID, this.a1RegistrationID[0]).subscribe((res) => {
        // console.log('A1 toggle data', res);
      });
    }
  }

  addAlHostCountries(id: number, event: MatSlideToggleChange) {
    if(event.checked ? confirm("Enable all countries checked") : confirm("Enable all countries unchecked")) {
      console.log("Enable A1 Host countries");
    }
  }

 

  /**
  * PWD Host Service
  */

  addPwdHostCountries(): void {
    const dialogRef = this.dialog.open(PwdHostComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: [this.questionDataList, this.registrationData]
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getRegistrations();
    });
  }

  addPWDHomeCountries(id: number, event: MatSlideToggleChange) {
    if(event.checked ? confirm("Enable all countries checked") : confirm("Enable all countries unchecked")) {
      console.log("Enable all PWD Home countries");
    }
  }

  addPwdAllCountries(id: number, event: MatSlideToggleChange) {

    if(event.checked ? confirm("Enable all countries checked") : confirm("Enable all countries unchecked")) {
      if (event.checked) {
        this.isPWDToggleChecked = true;
      } else {
        this.isPWDToggleChecked = false;
      }

      this.pwdResigrationID.push({
        "registrationID": id
      });
      this.questionCountryService.updateAllPwdCountries(this.questionID, this.pwdResigrationID[0]).subscribe((res) => {
        // console.log('Pwd toggle data', res);
      });
    }
  }

  // Option

  editQuestionOrder(data) {
    data.isEditable = true;
  }

  saveQuestionOrder(data) {

    this.newOrderID = [];
    console.log('saveQuestionOrder data', data);

    this.newOrderID.push({
      order: Number(data.order)
    });
    this.questionOptionService.udpateOptionByQuestionIdByOrder(this.questionID, data.id, this.newOrderID[0]).subscribe((res) => {
      console.log('After quesiton option data mapped', res);
      
      this.getQuestionOptionData();
    });
    data.isEditable = false;
  }

  refresh(): void {
    window.location.reload();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
