<div class="row">
    <div class="col-md-12">
        <mat-toolbar class="toolbar-header" fxLayout="row" fxLayoutAlign="space-between center" (window:resize)="onResize()" >
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span *ngIf="!showLogo"><img class="logo-image" src="assets/images/logo-deloitte.svg"
                        alt=" Deloitte Logo" />
                </span>
                <span *ngIf="showLogo"><img class="logo-image" src="assets/images/icons/favicon/android-icon-36x36.png"
                        alt=" Deloitte Favicon" />
                </span>
            </div>
            <span class="prehead" *ngIf="showQuestionLabel" >
                Midstream Questionnaire
            </span>
            <div>
                <ng-container *ngIf="orgsList && orgsList.length">
                    <span [matMenuTriggerFor]="org" class="orgList" style="cursor:pointer; margin-top:13px; font-size: 14px;">
                        <span>
                            {{orgName !== null ? orgName : 'BaseOrganization' }}
                        </span>
                        <span class="material-icons" style="font-size: 14px; position: relative; top: 2px;">
                            keyboard_arrow_down
                        </span>
                    </span>

                    <mat-menu #org="matMenu" xPosition="before">
                        <button type="button" *ngFor="let org of orgsList"
                         mat-menu-item (click)="selectOrg(org)">
                            {{org.organizationName}}
                        </button>
                    </mat-menu>
                </ng-container>
                <span class="userinfo mr-3 mt-2" [matMenuTriggerFor]="menu">
                    <span class="material-icons ml-3 mr-3" (click)="home($event)">home</span>
                    <span class="material-icons mr-3">help</span>
                    <span class="material-icons">
                        person
                    </span>
                    <span class="material-icons menu-button" style="font-size: 14px; position: relative; top: -4px;">
                        keyboard_arrow_down
                    </span>
                </span>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button type="button" mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </div>
        </mat-toolbar>
    </div>
</div>