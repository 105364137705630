import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionOptionService } from '../../../services/question-option.service';
import { QuestionService } from '../../../services/question.service';
import { SelectCountriesComponent } from '../select-countries/select-countries.component';
import { UpdateParentComponent } from '../update-parent/update-parent.component';

@Component({
  selector: 'app-edit-option',
  templateUrl: './edit-option.component.html',
  styleUrls: ['./edit-option.component.scss']
})
export class EditOptionComponent implements OnInit {

  optionQuestiondata: any = [];
  questionData: any = [];
  questionDataCopy: any = [];
  optionID: any;
  questionID: any;
  parentData: any;
  parentQuestionData: any = [];
  countriesColumn: string[] = ['id', 'name', 'code', 'actions'];
  parentColumn: string[] = ['id', 'name'];
  countriesData: MatTableDataSource<any> = null;
  answerKey: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,
    private questionOptionService: QuestionOptionService,
    private questionOption: QuestionOptionService,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.optionID = this.route.snapshot.params['id'];
    this.getQuestionOptionById(this.route.snapshot.params['id']);
    this.getOptionCountryList();
    
    this.questionService.newQuestionData.subscribe((res) => {
      console.log('new question data from question', res);
      this.questionData = res;
      console.log('parent data from question', this.questionData.parent);
    });

   this.getOptionParentList();
  }

  getOptionParentList(){
    this.questionOption.getOptionParent(this.optionID).subscribe((res) => {
      console.log('new parent question data', res.data);
      this.parentQuestionData = new MatTableDataSource<any>(res.data);
      this.parentQuestionData.paginator = this.paginator;
      this.parentQuestionData.sort = this.sort;
    });
  }

  getOptionCountryList() {
    this.questionOptionService.getOptionCountry(this.optionID).subscribe((res) => {
      console.log('Get option data', res);
      this.countriesData = res.data;
      this.countriesData = new MatTableDataSource<any>(res.data);
      this.countriesData.paginator = this.paginator;
      this.countriesData.sort = this.sort;
    });
  }

  getQuestionOptionById(id: number) {
    this.questionOptionService.getOptionByOptionId(id).subscribe((res) => {
      console.log('getOptionByOptionId', res);
      this.optionQuestiondata = res.data;
      this.answerKey = res.data.text;
    });
  }

  removeCountry(countryCode: string) {
    this.questionOption.removeOptionCountry(this.optionQuestiondata.id, countryCode).subscribe((res) => {
      console.log('Country removed from edit option', res);
      this.openSnackbarSuccessMessage('Country removed successfully');
      this.getOptionCountryList();
    });
  }

  // refresh() {

  // }

  selectCountries() {
    const dialogRef = this.dialog.open(SelectCountriesComponent, {
      width: '849px',
      autoFocus: false,
      data: this.optionQuestiondata
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed after countries selected', result);
      this.getOptionCountryList();
    });
  }

  updateParent() {
    const dialogRef = this.dialog.open(UpdateParentComponent, {
      width: '849px',
      autoFocus: false,
      data: this.optionQuestiondata
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed after parent updated', result);
      this.getOptionParentList();
    });
  }

  closeEditOption() {
    this.router.navigate(['/questions/questions/edit-question', this.questionData.id])
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
