<div class="row container mb-3">
    <div class="col-md-12">
        <h1 class="header">Create a language
        </h1>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="languageForm" (ngSubmit)="onSubmit()">
            <div class="row group-params mb-4">
                <div class="col-md-12">
                    <!-- <h2 class="sub-header">Group parameters</h2> -->
                    <mat-card>
                        <div class="row main-card">
                            <div class="col-md-12">
                                <div fxLayout="row" class="mt-3">
                                    <mat-form-field fxFlex="100">
                                        <label>Language</label>
                                        <input type="text" formControlName="language" class="textInput"
                                            aria-label="Group" matInput [matAutocomplete]="lang"
                                            #trigger="matAutocompleteTrigger" [errorStateMatcher]="matcher" pattern="^[A-Za-z\',\-\s]*$">
                                        <mat-icon
                                            (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                            class="custom-icon custom-form-field-icon">
                                            expand_more
                                        </mat-icon>
                                        <mat-autocomplete autoActiveFirstOption #lang="matAutocomplete"
                                            [displayWith]="langDisplay">
                                            <mat-option *ngFor="let lang of filteredLanuage | async" [value]="lang">
                                                {{lang.name}} - {{lang.code}} {{lang.directionality}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            {{languageErrorMessage}}
                                         </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex="100">
                                        <label>Country</label>
                                        <input type="text" formControlName="country" class="textInput"
                                            aria-label="Group" matInput [matAutocomplete]="country"
                                            #trigger="matAutocompleteTrigger" [errorStateMatcher]="matcher" pattern="^[A-Za-z\',\-\s]*$">
                                        <mat-icon
                                            (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                            class="custom-icon custom-form-field-icon">
                                            expand_more
                                        </mat-icon>
                                        <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                                            [displayWith]="displayFn">
                                            <mat-option *ngFor="let country of filteredCountry | async"
                                                [value]="country">
                                                {{country.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            {{countryErrorMessage}}
                                         </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="footer-btn">
                <div class="row bottom-button">
                    <div class="buttom-right">
                        <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                            Cancel
                        </button>
                        <button mat-raised-button type="submit" class="btnsave submitbtn"
                            [disabled]="languageForm.valid">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>