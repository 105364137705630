import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { ROLES } from 'src/app/shared/constants/constants';
import { AuthService } from '../../auth/auth.service';
import { AppService } from '../../services/app.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-org-select',
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.scss']
})
export class OrgSelectComponent implements OnInit {

  isSidenavabarVisible: boolean;

  constructor(
    private dialogRef: MatDialog,
    private auth: AuthService,
    private router: Router,
    private app: AppService,
    private questionService: QuestionService,
    private config: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('Org select comp mat dialog data', this.data);
  }

  selectOrg(org) {
    this.app.showLoader = true;
    this.auth.getEmployeeSSO(org).subscribe((res: any) => {
      this.app.showLoader = false;
      if (res && res.appAccessToken && res.ssoResponse) {
        this.close();
        localStorage.setItem('appToken', res.appAccessToken);
        localStorage.setItem('user_details', JSON.stringify(res.ssoResponse));

        setTimeout(() => {
          this.config.initAppConfig(res.ssoResponse);
        }, 100);
      }
      if (localStorage.getItem('user_details')) {
        let role = JSON.parse(localStorage.getItem('user_details'));
        if (role.appRoleName == ROLES.DEA || role.appRoleName == ROLES.DPA || role.appRoleName == ROLES.DET) {
          this.router.navigate(['questions/question-group']);
        }
      }
    });

    this.questionService.getSidenavbarData(true);
  }

  close() {
    this.dialogRef.closeAll();
  }

}
