import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';
import { Question } from '../models/question';
import { QuestionGroups } from '../models/questionGroups';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  envUrl: string;

  public questionData = new BehaviorSubject<any>('');
  newQuestionData = this.questionData.asObservable();

  public showSidenavbar = new Subject<boolean>();
  isSidenavbarVisible = this.showSidenavbar.asObservable();

  constructor(private http: HttpClient, private env: EnvironmentService) { 

    let newEnv = '';
    if(window.location.hostname.includes('dev-us')) {
      newEnv = 'dev-us';
    } else if(window.location.hostname.includes('qas-us')) {
      newEnv = 'qas-us';
    } else if(window.location.hostname.includes('dev-eu')) {
      newEnv = 'dev-eu';
    } else if(window.location.hostname.includes('qas-eu')) {
      newEnv = 'qas-eu';
    }

    this.envUrl = 'https://' + newEnv + '.pta.deloitte.com';
   }

  getNewQuestionData(data: any) {
    this.questionData.next(data);
  }

  getSidenavbarData(data: boolean) {
    this.showSidenavbar.next(data);
  }

  /** Question */

  getQuestions(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions?Show_Deleted=false`);
  }

  createQuestion(data: any): Observable<any> {
    return this.http.post<any>(`${this.env.API_URL}/api/v1/Questions`, data);
  }

  getQuestionsByGroupId(id: number): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions?Show_Deleted=false&Group=${id}`);
  }

  getQuestionById(questionID: any): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}`);
  }

  updateQuestion(questionID: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}`, data);
  }

  updateClientQuestion(questionID: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/ClientOverride/${questionID}`, data);
  }

  removeQuestion(questionID: any): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}`);
  }

  updateQuestionStatus(questionID: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/visibility`, data);
  }

  // Parent question

  updateParentQuestion(questionID: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}?isParent=true`, data);
  }

  removeParentQuestion(questionID: any, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}?isParent=false`, data);
  }

  // Order

  updateQuestionByOrder(groupID: number, questionID: number, data: any) {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/QuestionOrder/${groupID}/Order?questionID=${questionID}`, data);
  }

  // Translate

  getQuestionTranslateByLanguageCode(questionID: number, languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/translation/${languageCode}`);
  }

  updateQuestionTranslateByLanguageCode(questionID: number, languageCode: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Questions/${questionID}/translation/${languageCode}`, data);
  }

  // Tag

  getQuestionTag() {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Tag`);
  }

  // Registrations

  getRegistrations() {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Registration`);
  }

  getList<T>(url: string) {
    return this.http.get<T[]>(`${url}`);
  }

  // Versioning

  getActivityLog(questionID: number, data: boolean): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Versioning/${questionID}?show_Modified_Questions_From_Base=false`)
  }

  getModifiedQuestionsByBase(questionID: any, data: any): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/BaseVersioning/${questionID}?show_Modified_Questions_From_Base=true`);
  }

  getModifiedQuestions(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Questions/Question`);
  }
}
