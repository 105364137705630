import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLogComponent } from 'src/app/core/components/activity-log/activity-log.component';
import { BaseModifiedQuestionsComponent } from 'src/app/core/components/basemodifiedquestions/basemodifiedquestions.component';
import { AppService } from 'src/app/core/services/app.service';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionService } from '../../services/question.service';
import { TranslateQuestionComponent } from '../translate-question/translate-question.component';
import { Overlay, OverlayRef } from "@angular/cdk/overlay";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredCountries: Observable<string[]>;
  notification: string[] = ['A1', 'PWD', 'EE', 'PE'];
  options: string[] = ['One', 'Two', 'Three'];
  displayedColumns: string[] = [
    'alert',
    'position',
    'key',
    'registations',
    'type',
    'dependency',
    'status',
    'actions',
  ];
  allQuestions: MatTableDataSource<any> = null;
  show_modified_questions_from_base: any = true;

  tagName: any;
  countriesData: any = [];
  filteredQuestionData: any = [];
  filteredQuestionsArray : any = [];
  allQuestionsTempData: any = [];

  profileFilter = { 'is_Profile': true };
  staticFilter = { 'is_Static': true };
  encryptedFilter = { 'is_Encrypted': true };
  notificationFilter = { 'A1': 'A1', 'PWD': 'PWD' };
  statusFilter = ['enabled', 'disabled'];
  allCountries: any = [];
  questionLogData: any = [];
  registrationData: any = [];
  modified: boolean;

  questionKeyFilter = new FormControl('');
  isProfileFilter = new FormControl('');
  isStaticFilter = new FormControl('');
  isEncryptedFilter = new FormControl('');
  registrationFilter = new FormControl('');
  countryFilter = new FormControl('');
  visibilityFilter = new FormControl('');


  filterValues = {
    question: '',
    is_Profile: '',
    is_Static: '',
    is_Encrypted: '',
    registations: '',
    country: '',
    visibility: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private countriesService: CountriesService,
    private questionService: QuestionService,
    private snackBar: MatSnackBar,
    private app: AppService,
  ) { }

  ngOnInit() {
    this.allCountries = [];
    this.questionLogData = [];

    this.getQuestions();
     

    this.app.showLoader = true;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.filteredCountries = this.countryFilter.valueChanges.pipe(
    startWith(''),
    map((value) => this._filterCountries(value))
    );

    this.isProfileFilter.valueChanges.subscribe(
      (profile) => {
        console.log('profile', profile);
        if (profile !== null) {
          let profileType = Object.entries(this.profileFilter).filter((key) => key.includes(profile));
          this.filterValues.is_Profile = profileType.length > 0 && profileType[0][1] === true ? profileType[0][1].toString() : '';
          //this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isStaticFilter.valueChanges.subscribe(
      (is_Static) => {
        if (is_Static !== null) {
          let staticType = Object.entries(this.staticFilter).filter((key) => key.includes(is_Static));
          this.filterValues.is_Static = staticType.length > 0 && staticType[0][1] === true ? staticType[0][1].toString() : '';
          //this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isEncryptedFilter.valueChanges.subscribe(
      (encrypt) => {
        if (encrypt !== null) {
          let encryptType = Object.entries(this.encryptedFilter).filter((key) => key.includes(encrypt));
          this.filterValues.is_Encrypted = encrypt.length > 0 && encryptType[0][1] === true ? encryptType[0][1].toString() : '';
          //this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.questionKeyFilter.valueChanges
      .subscribe(
        question => {
          if (question !== null) {
            this.filterValues.question = question;
            //this.allQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );

    this.registrationFilter.valueChanges.subscribe(
      (service) => {
        if (service !== null) {
          this.filterValues.registations = service;
          //this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

       this.countryFilter.valueChanges
       .subscribe(
         country => {
           if (country !== null) {
             this.filterValues.country = country.toLowerCase();
             //this.allQuestions.filter = JSON.stringify(this.filterValues);
           }
         }
       );

      this.visibilityFilter.valueChanges
      .subscribe(
        visibile => {
          if (visibile !== null) {
            this.filterValues.visibility = visibile.toLowerCase();
            //this.allQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );

      this.countriesService.getAllCountries().subscribe((res) => {
        console.log('get all countries', res.data);
        this.countriesData = res.data;
  
      });

      this.questionService.getRegistrations().subscribe((res) => {
        console.log('get all registrations', res.data)
        this.registrationData = res.data;
      });


  }
  
  
  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countriesData.filter((option) =>
    option.name.toString().toLowerCase().includes(filterValue)
    );
  }

  getQuestions() {
    try {
      this.app.showLoader = true;
      this.questionService.getQuestions().subscribe((res: any) => {
        this.app.showLoader = false;
        console.log('Get all questions', res.data);

        res.data.forEach((e) => {
          if (e.tags.length !== 0) {
            this.tagName = e.tags[0].name;
          }

          if(e.type.toLowerCase() == 'number'){
            e.type = "numeric";
          }
        });
        this.filteredQuestionData = res.data;
        this.allQuestions = new MatTableDataSource<any>(res.data);
        this.allQuestions.paginator = this.paginator;
        this.allQuestions.sort = this.sort;
        this.applyFilter(); 
      });
    } catch (ex) { }

  }

  applyFilter(){
    this.filteredQuestionsArray = [];
    this.filteredQuestionData.filter((data: any, filter: string): boolean => {
      if (this.allQuestions.paginator) {
        this.allQuestions.paginator.firstPage();
      }
      let values = JSON.stringify(this.filterValues)
      let filterValue = JSON.parse(values);
      let conditions = true;

      for (let filterKey in filterValue) {
        if (filterKey === 'question' && filterValue[filterKey] != '') {
          conditions = conditions && data[filterKey].trim().toLowerCase().indexOf(filterValue[filterKey]) !== -1;
        }
        else if(filterKey === 'registations' && filterValue[filterKey] != ''){
          conditions = conditions && data.registations.filter((e) => { return (e.name === this.filterValues.registations)}).length > 0;
        }
        else if(filterKey === 'country' && filterValue[filterKey] != ''){
          conditions = conditions && data.questionCountry.filter((e) => { return (e.name.toLowerCase() === this.filterValues.country)}).length > 0;
        }
        else if((filterKey === 'is_Static' || filterKey === 'is_Profile' || filterKey === 'is_Encrypted') && filterValue[filterKey] != ''){
         let boolValue = filterValue[filterKey] != '' ? JSON.parse(filterValue[filterKey]): false;
         filterValue[filterKey] = boolValue;
         conditions = conditions && filterValue[filterKey] == data[filterKey];
        }
        else if (filterValue[filterKey].length) {
          conditions = conditions && filterValue[filterKey].includes(data[filterKey].trim().toLowerCase());
        }       
      }  
       
      if(conditions)
      {
        this.filteredQuestionsArray.push(data);
      }
      
      return conditions;
      });

      this.allQuestions = new MatTableDataSource<any>(this.filteredQuestionsArray);
      this.allQuestions.paginator = this.paginator;
      this.allQuestions.sort = this.sort;
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let regFound = data.registations.filter((e) => {
        return (e.name === searchTerms.registations)
      }).length > 0;

      return data.question.toLowerCase().indexOf(searchTerms.question) !== -1
        && String(data.is_Profile).includes(searchTerms.is_Profile)
        && String(data.is_Static).includes(searchTerms.is_Static)
        && String(data.is_Encrypted).includes(searchTerms.is_Encrypted)
        && regFound
        && data.visibility.includes(searchTerms.visibility);
        // && data.country.toLowerCase().includes(searchTerms.country)
    }
    return filterFunction;
  }

  clearFilter(event) {
    this.questionKeyFilter.reset();
    this.isProfileFilter.reset();
    this.isStaticFilter.reset();
    this.isEncryptedFilter.reset();
    this.registrationFilter.reset();
     this.countryFilter.reset();
    this.visibilityFilter.reset();
    this.allQuestions.filter = '';
    if (this.allQuestions.paginator) {
      this.allQuestions.paginator.firstPage();
    }
    this.filterValues = {
      question: '',
      is_Profile: '',
      is_Static: '',
      is_Encrypted: '',
      registations: '',
      country: '',
      visibility: ''
    };
      this.allQuestions = new MatTableDataSource<any>(this.filteredQuestionData);
      this.allQuestions.paginator = this.paginator;
      this.allQuestions.sort = this.sort;
    event.stopPropagation();
  }

  createQuestion() {
    this.router.navigate(['create-question'], { relativeTo: this.route });
  }

  editQuestion() {
    this.router.navigate(['edit-question'], { relativeTo: this.route });
  }

  translateQuestion(id) {
    const dialogRef = this.dialog.open(TranslateQuestionComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: id
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  removeQuestion(id: number) {
    this.app.showLoader = true;
    this.questionService.removeQuestion(id).subscribe((res) => {
      this.app.showLoader = false;
      this.openSnackbarSuccessMessage('Question removed successfully');
      this.getQuestions();
    });
  }

  viewActivityLog(id: number) {
    const dialogRef = this.dialog.open(ActivityLogComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: id
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  enableStatus(id: number, data: any) {
    if (data === 'disabled') {
      let updatedStatus = Object.assign({
        "visibility": "enabled"
      });
      this.app.showLoader = true;
      this.questionService.updateQuestionStatus(id, updatedStatus).subscribe((res) => {
        this.app.showLoader = false;
        console.log('Is status enabled', res);
        this.openSnackbarSuccessMessage('Question updated successfully');
        this.getQuestions();
      });
    }
  }

  disableStatus(id: number, data: any) {
    if (data === 'enabled') {
      let updatedStatus = Object.assign({
        "visibility": "disabled"
      });
      this.app.showLoader = true;
      this.questionService.updateQuestionStatus(id, updatedStatus).subscribe((res) => {
        this.app.showLoader = false;
        console.log('Is status disabled', res);
        this.openSnackbarSuccessMessage('Question updated successfully');
        this.getQuestions();
      });
    }
  }

  statusChange(id : number, value: string)  {
    if(value === "Enable")
    {
      this.enableStatus(id, 'disabled');      
    }
    else
    {
      this.disableStatus(id, 'enabled');
    }
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  openModifiedPopup(id: any){
    const dialogRef = this.dialog.open(BaseModifiedQuestionsComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: id
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getModifiedQuestions(){
    this.app.showLoader = true;
    let questionId = 0;
      this.questionService.getModifiedQuestionsByBase(questionId, true).subscribe((res: any) => {
        this.app.showLoader = false;
        console.log('Get all questions', res.data);
        this.allQuestionsTempData.forEach((item => {
           res.data.forEach((e) => {
            if(item.id === e.question_Id){
               item.IsUpdated = true;
            }
          });
        }));
    });
  }
}
