import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionOptionService } from '../../../services/question-option.service';

@Component({
  selector: 'app-select-countries',
  templateUrl: './select-countries.component.html',
  styleUrls: ['./select-countries.component.scss']
})
export class SelectCountriesComponent implements OnInit {

  listCountries: MatTableDataSource<any> = null;
  countryID: any = [];

  countriesColumn: string[] = ['id', 'name', 'actions'];
  countryForm: FormGroup;
  countriesData: any = [];
  allAvaialbleCountries: any = [];
  addButton: boolean;
  removeButton: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private questionOption: QuestionOptionService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SelectCountriesComponent>,
    private questionOptionService: QuestionOptionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.countryForm = new FormGroup({
        search: new FormControl(null)
      });
     }

  ngOnInit(): void {
      this.questionOptionService.getOptionCountry(this.data.id).subscribe((result) => {
        console.log('Get option data', result);
        this.countriesData = result.data;
        this.questionOption.getCountriesByHost().subscribe((res) => {
          console.log('List of host countries', res.data);
          this.listCountries = new MatTableDataSource<any>(res.data);
          this.listCountries.paginator = this.paginator;
          this.listCountries.sort = this.sort;
          this.allAvaialbleCountries = res.data;

          res.data.forEach((el) => {
            el['showButton'] = false;
          });
    
          res.data.forEach((data) => {
            this.countriesData.forEach((e) => {
              if (data.id === e.id) {
                data.showButton = true;
              }
            });
          });
          
          console.log('List of country ids', this.countryID);
        });
      });

    

  }

  addCountry(id: number) {
    this.countryID.push({
      "ids": [
        {
          "id": id
        }
      ]
    });
    this.questionOption.updateOptionCountry(this.data.id, this.countryID[0]).subscribe((res) => {
      console.log('Country added successfully', res);
      this.openSnackbarSuccessMessage('Country added successfully');
      this.ngOnInit();
    });
  }

  removeCountry(countryCode: string) {
    this.questionOption.removeOptionCountry(this.data.id, countryCode).subscribe((res) => {
      console.log('Country removed successfully', res);
      this.openSnackbarSuccessMessage('Country removed successfully');
      this.ngOnInit();
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  filterCountry() {
    if (this.countryForm.value.search !== null && this.listCountries) {
      this.listCountries.filter = this.countryForm.value.search.trim();
    }
  }

  clearFilter() {
    this.countryForm.patchValue({ search: '' });
    this.filterCountry();
  }
}
