import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountriesService } from '../../../countries/services/countries.service';
import { LocalizationService } from '../../services/localization.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-create-language',
  templateUrl: './create-language.component.html',
  styleUrls: ['./create-language.component.scss'],
})
export class CreateLanguageComponent implements OnInit {

  filteredLanuage: Observable<string[]>;
  filteredCountry: Observable<string[]>;
  countryFilterData: any = [];
  languageFilterData: any = [];

  languageForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  languageList: any = [];
  countriesList: any = [];
  languageData: any = [];
  newLanguageData: any = [];
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private localizationService: LocalizationService,
    private countriesService: CountriesService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  get countryErrorMessage(): string {
    const form: FormControl = (this.languageForm.get('country') as FormControl);
    return form.hasError('required') ?
      'Country is required' :
      form.hasError('pattern', form.value.name) ?
      'Please enter a character': '';
 }

 get languageErrorMessage(): string {
  const form: FormControl = (this.languageForm.get('language') as FormControl);
  return form.hasError('required') ?
    'Language is required' :
    form.hasError('pattern', form.value.name) ?
    'Please enter a character': '';
}


  ngOnInit() {

    this.localizationService.getLanguageList().subscribe((res) => {
      console.log('Get langugae list', res);
      this.languageList = res.data;
      this.languageFilterData.push(this.languageList);

      this.filteredLanuage = this.languageForm.get('language').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.languageFilterData[0].filter((language: any) => language.name.toLowerCase().includes(term))
        })
      );
    });

    this.countriesService.getAllCountries().subscribe((res) => {
      console.log('Get countries list', res);
      this.countriesList = res.data;
      this.countryFilterData.push(res.data);

      this.filteredCountry = this.languageForm.get('country').valueChanges.pipe(
        startWith(''),
        map((term) => {
          return this.countryFilterData[0].filter((country: any) => country.name.toLowerCase().includes(term))
        })
      );
    });

    this.languageForm = this.fb.group({
      language: ['', [Validators.required, Validators.pattern('^[A-Za-z\',\-\s]*$')]],
      country: ['', [Validators.required, Validators.pattern('^[A-Za-z\',\-\s]*$')]]
     });
    
  }

  

  displayFn(country: any) {
   return country ? country.name : '';
  }

  langDisplay(lang: any) {
    return lang ? [lang.name + ' - ' + lang.code + ' '+ lang.directionality] : '';
  }

  get langData() {
    return this.languageForm.controls;
  }

  onSubmit() {

    // if(this.languageData.language) {
    //   if(this.languageData.language.value) {

    //   }
    // }

    this.newLanguageData = [];

    this.newLanguageData.push({
      languageListId: this.langData.language.value.id,
      countryId: this.langData.country.value.id
    });

    this.localizationService.createLanguage(this.newLanguageData[0]).subscribe(
      (res) => {
        console.log(JSON.stringify(res));
        this.openSnackbarSuccessMessage('Language created successfully');
        this.router.navigate(['/languages']);
      },
      (error) => {
        if(error.error.message === "Language already exists. Choose another language") {
          this.openSnackbarSuccessMessage('Language already exists. Choose another language');
          this.languageForm.reset();
        }
      }
    );
  }

  exitGroup() {
    this.router.navigate(['/languages']);
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
